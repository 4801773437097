import React, { useState, useEffect, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PaginationNav1Presentation } from "../Utility/Pagination";
import { BsSearch } from "react-icons/bs";
import { HiEllipsisVertical } from "react-icons/hi2";
import LoadingCircle from "../Utility/LoadingCircle";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { IoArrowBackCircle } from "react-icons/io5";

export const Services = ({ api, sidebarOpen, token, isCommercial }) => {
	const { user } = useAuth0();
	const [cleaningOpen, setCleaningOpen] = useState(false);
	const [serviceOpen, setServiceOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [ascending, setAscending] = useState(false);
	const [tickets, setTickets] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const [firstIndex, setFirstIndex] = useState(0);
	const [lastIndex, setLastIndex] = useState(0);
	const [globalPage, setGlobalPage] = useState(0);
	const [searchQuery, setSearchQuery] = useState("");
	const [searchParams] = useSearchParams();
	const { state } = useLocation();
	const address = state && state.name;

	const [openSelectionMenu, setOpenSelectionMenu] = useState(false);
	const [idSelected, setIdSelected] = useState(true);
	const [workOrderSelected, setWorkOrderSelected] = useState(true);
	const [statusSelected, setStatusSelected] = useState(true);
	const [addressSelected, setAddressSelected] = useState(true);
	const [categorySelected, setCategorySelected] = useState(true);
	const [lastModifiedSelected, setLastModifiedSelected] = useState(true);
	const nameSelected = useRef(true);
	const navigate = useNavigate();

	const [headers, setHeaders] = useState([
		{ id: "name", title: "Name", field: "name" },
		{ id: "ticketid", title: "Ticket Id", field: "id", link: true },
		{ id: "customerReference#", title: "Customer Reference #", field: "x_work_order_number" },
		{ id: "status", title: "Status", field: "stage_id" },
		{ id: "address", title: "Address", field: "x_studio_site_street" },
		{ id: "category", title: "Category", field: "x_studio_issue_type" },
		{ id: "lastModifiedDate", title: "Last Modified Date", field: "write_date" },
	]);

	const ticketData = tickets.slice(firstIndex, lastIndex + 1);

	useEffect(() => {
		if (!isCommercial || !token) {
			return;
		}

		const controller = new AbortController();
		setIsLoading(true);
		fetch(api + "/services", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify({
				userEmail: user.email,
				isCommercial: isCommercial,
			}),
			mode: "cors",
			signal: controller.signal,
		})
			.then((res) => {
				return res.json();
			})
			.then((res) => {
				setIsLoading(false);
				setTickets(res.tickets);
			})
			.catch((error) => {});

		return () => controller.abort();
	}, [api, user.email, token, isCommercial]);

	useEffect(() => {
		setPageCount(Math.ceil(tickets.length / 10));
		setFirstIndex(globalPage * 10);
		setLastIndex(10 * (globalPage + 1) < tickets.length ? 10 * (globalPage + 1) - 1 : tickets.length - 1);
	}, [tickets, globalPage]);

	useEffect(() => {
		if (api === "" || !isCommercial || !token) {
			return;
		}

		let query = "?";
		const searchParams = new URLSearchParams(window.location.search);
		const queryParam = searchParams.get("query");
		if (queryParam) {
			const encodedQuery = encodeURIComponent(queryParam);
			query = `?query=${encodedQuery}`;
			let decodedQuery = decodeURIComponent(encodedQuery);
			setSearchQuery(decodedQuery === " " ? "" : decodedQuery);
		}

		const controller = new AbortController();
		const signal = controller.signal;
		setIsLoading(true);
		setTickets([]);
		fetch(api + "/services/search" + query, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify({
				userEmail: user.email,
				isCommercial: isCommercial,
			}),
			mode: "cors",
			signal,
		})
			.then((res) => {
				if (res.ok) {
					return res.json();
				}
			})
			.then((res) => {
				setIsLoading(false);
				if (isCommercial) {
					setTickets(res.tickets);
				}
			})
			.catch(function (error) {});
		return () => {
			controller.abort();
		};
	}, [api, searchParams, token, user.email, isCommercial]);

	const searchServices = (e) => {
		e.preventDefault();

		const uriEncodedString = encodeURIComponent(searchQuery.trim());
		if (searchQuery !== "") {
			navigate(`/services/search?query=${uriEncodedString}`);
		} else {
			navigate(`/services/search?query=${encodeURIComponent(" ")}`);
		}
	};

	const isHeaderSelected = (headerId) => {
		switch (headerId) {
			case "ticketid":
				return idSelected;
			case "customerReference#":
				return workOrderSelected;
			case "status":
				return statusSelected;
			case "address":
				return addressSelected;
			case "category":
				return categorySelected;
			case "lastModifiedDate":
				return lastModifiedSelected;
			default:
				return nameSelected;
		}
	};

	const handleDragEnd = (result) => {
		if (!result.destination) return;

		const updatedColumnOrder = Array.from(headers);
		const [movedColumn] = updatedColumnOrder.splice(result.source.index, 1);
		updatedColumnOrder.splice(result.destination.index, 0, movedColumn);

		setHeaders(updatedColumnOrder);
	};

	const handleSortServices = (headerId) => {
		const sortedServiceData = [...tickets];

		if (headerId === "name") {
			const serviceHeader = headers.find((header) => header.id === headerId);
			const currentSorted = serviceHeader.sorted;

			sortedServiceData.sort((a, b) => {
				const nameA = getProjectNames(a.name);
				const nameB = getProjectNames(b.name);

				let comparison = 0;
				if (nameA > nameB) {
					comparison = 1;
				}
				if (nameA < nameB) {
					comparison = -1;
				}
				return currentSorted ? comparison * -1 : comparison;
			});
			serviceHeader.sorted = !currentSorted;

			setTickets(sortedServiceData);
			setHeaders([...headers]);
			setAscending(!ascending);
		} else if (headerId === "ticketid") {
			const ticketIdHeader = headers.find((header) => header.id === headerId);
			const currentSorted = ticketIdHeader.sorted;

			const sorted = ascending ? -1 : 1;

			sortedServiceData.sort((a, b) => {
				const sizeA = a.id;
				const sizeB = b.id;

				return sorted * (sizeA - sizeB);
			});
			ticketIdHeader.sorted = !currentSorted;

			setTickets(sortedServiceData);
			setHeaders([...headers]);
			setAscending(!ascending);
		} else if (headerId === "customerReference#") {
			const customerReferenceHeader = headers.find((header) => header.id === headerId);
			const currentSorted = customerReferenceHeader.sorted;

			sortedServiceData.sort((a, b) => {
				const numberA = a.x_work_order_number ? a.x_work_order_number : "";
				const numberB = b.x_work_order_number ? b.x_work_order_number : "";

				let comparison = 0;
				if (numberA > numberB) {
					comparison = 1;
				}
				if (numberA < numberB) {
					comparison = -1;
				}
				return currentSorted ? comparison * -1 : comparison;
			});
			customerReferenceHeader.sorted = !currentSorted;

			setTickets(sortedServiceData);
			setHeaders([...headers]);
			setAscending(!ascending);
		} else if (headerId === "status") {
			const statusHeader = headers.find((header) => header.id === headerId);
			const currentSorted = statusHeader.sorted;

			sortedServiceData.sort((a, b) => {
				const statusA = a.stage_id ? a.stage_id : "";
				const statusB = b.stage_id ? b.stage_id : "";

				let comparison = 0;
				if (statusA > statusB) {
					comparison = 1;
				}
				if (statusA < statusB) {
					comparison = -1;
				}
				return currentSorted ? comparison * -1 : comparison;
			});
			statusHeader.sorted = !currentSorted;

			setTickets(sortedServiceData);
			setHeaders([...headers]);
			setAscending(!ascending);
		} else if (headerId === "address") {
			const addressHeader = headers.find((header) => header && header.id === headerId);
			const currentSorted = addressHeader.sorted;

			sortedServiceData.sort((a, b) => {
				const nameA = a.x_studio_site_street ? a.x_studio_site_street.replace(/[0-9]/g, "") : "";
				const nameB = b.x_studio_site_street ? b.x_studio_site_street.replace(/[0-9]/g, "") : "";

				let comparison = 0;
				if (nameA > nameB) {
					comparison = 1;
				}
				if (nameA < nameB) {
					comparison = -1;
				}
				return currentSorted ? comparison * -1 : comparison;
			});
			addressHeader.sorted = !currentSorted;

			setTickets(sortedServiceData);
			setHeaders([...headers]);
			setAscending(!ascending);
		} else if (headerId === "category") {
			const categoryHeader = headers.find((header) => header.id === headerId);
			const currentSorted = categoryHeader.sorted;

			sortedServiceData.sort((a, b) => {
				const categoryA = a.x_studio_issue_type ? a.x_studio_issue_type : "";
				const categoryB = b.x_studio_issue_type ? b.x_studio_issue_type : "";

				let comparison = 0;
				if (categoryA > categoryB) {
					comparison = 1;
				}
				if (categoryA < categoryB) {
					comparison = -1;
				}
				return currentSorted ? comparison * -1 : comparison;
			});
			categoryHeader.sorted = !currentSorted;

			setTickets(sortedServiceData);
			setHeaders([...headers]);
			setAscending(!ascending);
		} else if (headerId === "lastModifiedDate") {
			const lastModifiedSorted = headers.find((header) => header.id === headerId);
			const currentSorted = lastModifiedSorted.sorted;

			sortedServiceData.sort((a, b) => {
				const dateA = a.write_date ? a.write_date : "";
				const dateB = b.write_date ? b.write_date : "";

				let comparison = 0;
				if (dateA > dateB) {
					comparison = 1;
				}
				if (dateA < dateB) {
					comparison = -1;
				}
				return currentSorted ? comparison * -1 : comparison;
			});
			lastModifiedSorted.sorted = !currentSorted;

			setTickets(sortedServiceData);
			setHeaders([...headers]);
			setAscending(!ascending);
		}
	};

	const getProjectNames = (projectName) => {
		const name = projectName.split("-");
		if (name.length >= 2) {
			return name[0].trim();
		} else {
			return projectName.trim();
		}
	};

	return (
		<div
			className={`fixed top-0 left-0 right-0 bottom-0 z-[-1] bg-cover overflow-auto ${
				user.email === "ps@1stle.com"
					? "bg-PSBackgroundImage px-10"
					: user.email === "ess@1stle.com"
					? "bg-ESSBackgroundImage px-10"
					: isCommercial
					? "bg-CommercialBackgroundImage px-10"
					: "bg-1stleBackgroundImage"
			}`}
			onClick={() => (openSelectionMenu ? setOpenSelectionMenu(!openSelectionMenu) : "")}>
			<div className={sidebarOpen ? "md:ml-64" : "md:ml-10"}>
				{!isCommercial ? (
					<div className="flex items-center justify-center">
						<div className="bg-slate-50 shadow-md px-5 md:px-60 pt-6 pb-8 mt-10 mb-10 ml-10 mr-10 relative w-full md:w-[1200px] bg-opacity-95">
							<div className="flex flex-col items-center justify-center mb-14">
								<div
									className="absolute top-5 left-5 p-3 flex items-center justify-center gap-2 w-32 cursor-pointer bg-slate-300 hover:bg-slate-400"
									onClick={() => navigate(-1)}>
									<IoArrowBackCircle className="text-2xl cursor-pointer" />
									<p className="cursor-pointer">Go back</p>
								</div>
							</div>
							<div className="flex items-center justify-center">
								<span className="text-2xl md:text-5xl font-bold">Services</span>
							</div>
							<div className="flex items-center justify-center mt-5">
								<span className="text-lg md:text-xl">Address: {address}</span>
							</div>
							<div className="flex flex-col md:flex-row items-center justify-center mt-10 gap-10">
								<div className={`max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow-xl ${serviceOpen ? "mb-auto" : ""}`}>
									<img
										className="w-80 h-40 cursor-pointer transform transition duration-500 hover:scale-105"
										src={process.env.REACT_APP_PANEL_CLEANING_IMAGE_URL}
										alt="Panel Cleaning"
										onClick={() => setCleaningOpen(!cleaningOpen)}
									/>
									<h1 className="flex items-center justify-center mb-2 mt-2 text-lg md:text-xl font-bold tracking-tight text-gray-700">
										Panel Cleaning
									</h1>
									<div className={!cleaningOpen ? "hidden" : undefined}>
										<p className="text-sm md:text-base mb-3 font-normal text-gray-700">Duration: 4 Hr</p>
										<p className="text-sm md:text-base mb-3 font-normal text-gray-700">Fee: $350.00 each</p>
										<p className="text-sm md:text-base mb-3 font-normal text-gray-700">
											***Site visit fee's non-refundable after 24 <br /> hours of payment, except for <br /> Workmanship related
											Issue's***
										</p>
										<button
											onClick={() => navigate("/services/panel-cleaning")}
											className="bg-blue-500 hover:bg-blue-600 text-white w-full h-10 mt-10">
											Select
										</button>
									</div>
								</div>
								<div className={`max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow-xl ${cleaningOpen ? "mb-auto" : ""}`}>
									<img
										className="w-80 h-40 cursor-pointer transform transition duration-500 hover:scale-105"
										src={process.env.REACT_APP_SERVICE_IMAGE_URL}
										alt="Panel Cleaning"
										onClick={() => setServiceOpen(!serviceOpen)}
									/>
									<h1 className="flex items-center justify-center mb-2 mt-2 text-lg md:text-xl font-bold tracking-tight text-gray-700">
										Service Visit
									</h1>
									<div className={!serviceOpen ? "hidden" : undefined}>
										<p className="text-sm md:text-base mb-3 font-normal text-gray-700">Duration: 2 Hr</p>
										<p className="text-sm md:text-base mb-3 font-normal text-gray-700">Fee: $500.00 each</p>
										<p className="text-sm md:text-base mb-3 font-normal text-gray-700">
											***Site visit fee's non-refundable after 24 <br /> hours of payment, except for <br /> Workmanship related
											Issue's***
										</p>
										<button
											onClick={() => navigate("/services/service-visit")}
											className="bg-blue-500 hover:bg-blue-600 text-white w-full h-10 mt-10">
											Select
										</button>
									</div>
								</div>
							</div>
							<p
								className={`flex flex-col items-center justify-center mt-3 text-lg md:text-xl font-normal text-gray-700 ${
									cleaningOpen || serviceOpen ? "hidden" : ""
								}`}>
								Got a question about a product or service?{" "}
								<a className="font-medium text-blue-600 hover:underline ml-1" href="/contact">
									Ask Us
								</a>
							</p>
						</div>
					</div>
				) : (
					<div>
						<div className="flex items-center justify-center py-10">
							{user.email === "ps@1stle.com" ? (
								<img className="h-32" src={process.env.REACT_APP_PUBLIC_STORAGE_LOGO_URL} alt="PUBLIC STORAGE" />
							) : user.email === "ess@1stle.com" ? (
								<img className="h-32" src={process.env.REACT_APP_EXTRA_SPACE_STORAGE_LOGO_URL} alt="EXTRA SPACE STORAGE" />
							) : (
								""
							)}
						</div>
						<form onSubmit={(e) => searchServices(e)} className="my-4 flex w-full justify-center items-center">
							<input
								type="text"
								className="border border-black p-2 w-full rounded-l-lg"
								placeholder="Search for services"
								spellCheck={false}
								value={searchQuery}
								onChange={(e) => setSearchQuery(e.target.value)}
							/>
							<button type="submit" className="border rounded-r-lg border-black bg-white p-3">
								<BsSearch />
							</button>
						</form>
						<DragDropContext onDragEnd={handleDragEnd}>
							<Droppable droppableId="headers" direction="horizontal">
								{(provided) => (
									<ul className="headers" {...provided.droppableProps} ref={provided.innerRef}>
										<div className="w-full border border-black p-5 hidden md:flex justify-between bg-black text-white">
											{headers
												.filter((header) => header !== null)
												.map(
													(header, index) =>
														isHeaderSelected(header.id) && (
															<Draggable key={header.id} draggableId={header.id} index={index}>
																{(provided) => (
																	<div
																		className="md:basis-1/4"
																		ref={provided.innerRef}
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}>
																		<div
																			className={`flex items-center ${header.id !== "name" ? "justify-center" : ""} ${
																				header.id !== "name" && !header.link ? "ml-10" : ""
																			}`}>
																			<p key={index}>{header.title}</p>
																			{header.id === "name" && (
																				<FontAwesomeIcon
																					icon={header.sorted ? faCaretUp : faCaretDown}
																					className="ml-2 cursor-pointer"
																					onClick={() => handleSortServices(header.id)}
																				/>
																			)}
																			{header.id === "ticketid" && (
																				<FontAwesomeIcon
																					icon={header.sorted ? faCaretUp : faCaretDown}
																					className="ml-2 cursor-pointer"
																					onClick={() => handleSortServices(header.id)}
																				/>
																			)}
																			{header.id === "customerReference#" && (
																				<FontAwesomeIcon
																					icon={header.sorted ? faCaretUp : faCaretDown}
																					className="ml-2 cursor-pointer"
																					onClick={() => handleSortServices(header.id)}
																				/>
																			)}
																			{header.id === "status" && (
																				<FontAwesomeIcon
																					icon={header.sorted ? faCaretUp : faCaretDown}
																					className="ml-2 cursor-pointer"
																					onClick={() => handleSortServices(header.id)}
																				/>
																			)}
																			{header.id === "address" && (
																				<FontAwesomeIcon
																					icon={header.sorted ? faCaretUp : faCaretDown}
																					className="ml-2 cursor-pointer"
																					onClick={() => handleSortServices(header.id)}
																				/>
																			)}
																			{header.id === "category" && (
																				<FontAwesomeIcon
																					icon={header.sorted ? faCaretUp : faCaretDown}
																					className="ml-2 cursor-pointer"
																					onClick={() => handleSortServices(header.id)}
																				/>
																			)}
																			{header.id === "lastModifiedDate" && (
																				<FontAwesomeIcon
																					icon={header.sorted ? faCaretUp : faCaretDown}
																					className="ml-2 cursor-pointer"
																					onClick={() => handleSortServices(header.id)}
																				/>
																			)}
																		</div>
																	</div>
																)}
															</Draggable>
														)
												)}
											<div className="flex items-center">
												<HiEllipsisVertical
													size={25}
													className="cursor-pointer"
													onClick={() => setOpenSelectionMenu(!openSelectionMenu)}
												/>
											</div>
										</div>
										{openSelectionMenu ? (
											<div className="flex justify-end" onClick={(e) => e.stopPropagation()}>
												<div id="selectionDropdown" className="w-48 bg-white rounded-lg absolute shadow z-50">
													<ul className="p-3 space-y-1 text-sm text-gray-700" aria-labelledby="selectionDropdown">
														<li>
															<div className="flex items-center p-2 rounded hover:bg-gray-100">
																<input
																	id="id"
																	type="checkbox"
																	value="id"
																	className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
																	checked={idSelected}
																	onChange={() => setIdSelected(!idSelected)}
																/>
																<label htmlFor="id" className="w-full ms-2 text-sm font-medium text-gray-900 rounded">
																	Ticket Id
																</label>
															</div>
														</li>
														<li>
															<div className="flex items-center p-2 rounded hover:bg-gray-100">
																<input
																	id="workOrder"
																	type="checkbox"
																	value="workOrder"
																	className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
																	checked={workOrderSelected}
																	onChange={() => setWorkOrderSelected(!workOrderSelected)}
																/>
																<label htmlFor="workOrder" className="w-full ms-2 text-sm font-medium text-gray-900 rounded">
																	Customer Reference #
																</label>
															</div>
														</li>
														<li>
															<div className="flex items-center p-2 rounded hover:bg-gray-100">
																<input
																	id="status"
																	type="checkbox"
																	value="status"
																	className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
																	checked={statusSelected}
																	onChange={() => setStatusSelected(!statusSelected)}
																/>
																<label htmlFor="status" className="w-full ms-2 text-sm font-medium text-gray-900 rounded">
																	Status
																</label>
															</div>
														</li>
														<li>
															<div className="flex items-center p-2 rounded hover:bg-gray-100">
																<input
																	id="address"
																	type="checkbox"
																	value="address"
																	className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
																	checked={addressSelected}
																	onChange={() => setAddressSelected(!addressSelected)}
																/>
																<label htmlFor="address" className="w-full ms-2 text-sm font-medium text-gray-900 rounded">
																	Address
																</label>
															</div>
														</li>
														<li>
															<div className="flex items-center p-2 rounded hover:bg-gray-100">
																<input
																	id="category"
																	type="checkbox"
																	value="category"
																	className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
																	checked={categorySelected}
																	onChange={() => setCategorySelected(!categorySelected)}
																/>
																<label htmlFor="category" className="w-full ms-2 text-sm font-medium text-gray-900 rounded">
																	Category
																</label>
															</div>
														</li>
														<li>
															<div className="flex items-center p-2 rounded hover:bg-gray-100">
																<input
																	id="lastModifiedDate"
																	type="checkbox"
																	value="lastModifiedDate"
																	className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
																	checked={lastModifiedSelected}
																	onChange={() => setLastModifiedSelected(!lastModifiedSelected)}
																/>
																<label
																	htmlFor="lastModifiedDate"
																	className="w-full ms-2 text-sm font-medium text-gray-900 rounded">
																	Last Modified Date
																</label>
															</div>
														</li>
													</ul>
												</div>
											</div>
										) : null}
										{tickets.length > 0 ? (
											ticketData.map((value, index) => (
												<div
													key={index}
													className={`gap-3 border border-black p-3 flex flex-col md:flex-row justify-between items-center ${
														user.email === "ps@1stle.com"
															? index % 2 === 0
																? "bg-gray-300"
																: "bg-white"
															: user.email === "ess@1stle.com"
															? index % 2 === 0
																? "bg-lime-400"
																: "bg-white"
															: index % 2 === 0
															? "bg-yellow-200"
															: "bg-gray-100"
													}`}>
													{headers
														.filter((header) => header !== null)
														.map((header, columnIndex) => {
															return (
																isHeaderSelected(header.id) && (
																	<div key={columnIndex} className="md:basis-1/2">
																		<div className={`flex items-center ${header.id !== "name" ? "justify-center" : ""}`}>
																			{header.field === "stage_id" ? (
																				value[header.field] && value[header.field][1]
																			) : header.id === "ticketid" ? (
																				<button
																					className={`inline-flex items-center py-2 px-4 text-md font-medium text-center text-black rounded-full focus:ring-4 focus:ring-primary-200 cursor-pointer ${
																						user.email === "ps@1stle.com"
																							? "bg-orange-500 hover:bg-orange-600"
																							: user.email === "ess@1stle.com"
																							? "bg-green-500 hover:bg-green-600"
																							: "bg-yellow-400 hover:bg-yellow-500"
																					}`}
																					onClick={() =>
																						navigate("/tickets/" + value.id, { state: { id: 1, name: value.id } })
																					}>
																					{value ? value[header.field] : ""}
																				</button>
																			) : header.field === "write_date" ? (
																				value[header.field] ? (
																					new Date(value[header.field]).toLocaleDateString()
																				) : (
																					""
																				)
																			) : (
																				value[header.field]
																			)}
																		</div>
																	</div>
																)
															);
														})}
												</div>
											))
										) : isLoading ? (
											<div className="flex flex-col items-center justify-center w-full my-2">
												<LoadingCircle />
												<p className="text-white">Loading services</p>
											</div>
										) : (
											<p className="text-white text-center text-xl m-4">No Results Found</p>
										)}
										<div className="bg-black bg-opacity-20 text-center py-10">
											<span className="text-lg text-white font-bold">
												Showing
												<span className="font-semibold text-white"> {globalPage * 10 + 1}</span> to
												<span className="font-semibold text-white">
													{" "}
													{10 * (globalPage + 1) < tickets.length ? 10 * (globalPage + 1) : tickets.length}
												</span>{" "}
												of
												<span className="font-semibold text-white"> {tickets.length}</span> Entries
											</span>

											<nav aria-label="Ticket Navigation">
												<ul className="inline-flex items-center -space-x-px h-16">
													{PaginationNav1Presentation(pageCount, searchQuery, globalPage, setGlobalPage)}
												</ul>
											</nav>
										</div>
										{provided.placeholder}
									</ul>
								)}
							</Droppable>
						</DragDropContext>
					</div>
				)}
			</div>
		</div>
	);
};
