export default function ExpectationsCalculator({ contractSystemSize, productionFactor, monthlyPercentages, systemTurnOnDay, systemTurnOnDate }) {
    const expectedTotal = productionFactor * contractSystemSize;
    const months = ["Jan (kWh)", "Feb (kWh)", "Mar (kWh)", "Apr (kWh)", "May (kWh)", "Jun (kWh)", "Jul (kWh)", "Aug (kWh)", "Sep (kWh)", "Oct (kWh)", "Nov (kWh)", "Dec (kWh)"];
    const dateParts = systemTurnOnDate.split('-');
    const turnOnMonth = parseInt(dateParts[0], 10) - 1;
    const turnOnDay = parseInt(dateParts[1], 10);
    const turnOnYear = parseInt(dateParts[2], 10);

    const calculateInitialValues = (expectedTotal) => {
        if (productionFactor <= 0) {
            return { values: {} };
        }

        const values = {};
        for (let year = turnOnYear; year <= turnOnYear + 10; year++) {
            values[year] = Array(12).fill(0);
        }

        const firstMonthValue = Math.round((monthlyPercentages[turnOnMonth] / 100) * expectedTotal * (1 - (turnOnDay / 31)));
        values[turnOnYear][turnOnMonth] = firstMonthValue;

        for (let month = turnOnMonth + 1; month < 12; month++) {
            values[turnOnYear][month] = Math.round((monthlyPercentages[month] / 100) * expectedTotal);
        }

        for (let year = turnOnYear + 1; year <= turnOnYear + 10; year++) {
            for (let month = 0; month < 12; month++) {
                values[year][month] = Math.round((monthlyPercentages[month] / 100) * expectedTotal * Math.pow(0.995, year - turnOnYear));
            }
        }

        return { values };
    };

    const generateRows = () => {
        let rows = [];
        const { values } = calculateInitialValues(expectedTotal);

        for (let year = turnOnYear; year <= turnOnYear + 9; year++) {
            rows.push(
                <tr key={year} className="border-b">
                    <td className="py-2 px-4 font-bold">{year}</td>
                    {months.map((month, monthIndex) => {
                        let kWhValue = 0;
                        if (values[year] && values[year][monthIndex] !== undefined) {
                            kWhValue = (year === turnOnYear && monthIndex < turnOnMonth) ? 0 : values[year][monthIndex];
                        }
                        return <td key={monthIndex} className="py-2 px-4">{kWhValue}</td>;
                    })}
                </tr>
            );
        }

        return rows;
    };

    return (
        <div className="overflow-x-auto mb-4">
            <table className="min-w-full bg-white border border-gray-200">
                <thead>
                    <tr className="bg-black text-white border-b">
                        <th className="py-2 px-4 text-left">Year</th>
                        {months.map((month, index) => (
                            <th key={index} className="py-2 px-4 text-left">{month}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {generateRows()}
                </tbody>
            </table>
        </div>
    );
}